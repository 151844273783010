.couponwrapSkeleton {
    width: 100%;
    height: 156px;
    border-radius: 12px;
    flex-grow: 1;
    position: relative;
}

.couponwrapSkeleton::before {
    position: absolute;
    content: "";
    height: 24px;
    left: -15px;
    border-radius: 50%;
    z-index: 1;
    top: calc(50% - 14px);
    background-color: #f1f1f1;
    width: 24px;
}

.couponwrapSkeleton::after {
    position: absolute;
    content: "";
    height: 24px;
    right: -15px;
    border-radius: 50%;
    z-index: 1;
    top: calc(50% - 14px);
    background-color: #f1f1f1;
    width: 24px;
}

.couponwrap {
    width: 100%;
    border-radius: 12px;
    height: 156px;
    flex-grow: 1;
    position: relative;
    display: flex;
}

.couponwrap::before {
    position: absolute;
    content: "";
    height: 24px;
    left: -15px;
    border-radius: 50%;
    z-index: 1;
    top: calc(50% - 14px);
    background-color: #f1f1f1;
    width: 24px;
}

.couponwrap::after {
    position: absolute;
    content: "";
    height: 24px;
    right: -15px;
    border-radius: 50%;
    z-index: 1;
    top: calc(50% - 14px);
    background-color: #f1f1f1;
    width: 24px;
}

@media (max-width: 584.98px) {
    .couponwrap {
        height: 123px;
    }

    .buttonGetCoupon {
        bottom: 5px;
        right: 5px;
    }
}