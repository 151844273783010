@media (max-width: 1140.98px) {
  .NotifyMeTextFull {
    display: none;
  }
  .NotifyMeTextShort {
    display: block;
  }
}

@media (max-width: 1033.98px) {
  .NotifyMeTextImage {
    display: none;
  }
}

@media (max-width: 975.98px) {
  .NotifyMeTextImage {
    display: block;
  }
  .NotifyMeTextFull {
    display: block;
  }
  .NotifyMeTextShort {
    display: none;
  }
}

@media (max-width: 814.98px) {
  /* .NotifyMeTextImage {
    display: block;
  } */
  .NotifyMeTextFull {
    display: none;
  }
  .NotifyMeTextShort {
    display: block;
  }
}

@media (max-width: 767.98px) {
  /* .NotifyMeTextImage {
    display: block;
  } */
  .NotifyMeTextFull {
    display: block;
  }
  .NotifyMeTextShort {
    display: none;
  }
}

@media (max-width: 509.98px) {
  /* .NotifyMeTextImage {
    display: block;
  } */
  .NotifyMeTextFull {
    display: none;
  }
  .NotifyMeTextShort {
    display: block;
  }
}
@media (max-width: 444.98px) {
  .NotifyMeTextImage {
    display: block;
  }
  .NotifyMeTextFull {
    display: none;
  }
  .NotifyMeTextShort {
    display: none;
  }
}
