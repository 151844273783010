.speechBubble {
  position: absolute;
  background: white;
  border-radius: 0.4em;
  padding: 0.5em;
  z-index: 11 !important;
  width: 120px;
  min-height: 120px;
}

.speechBubble.bubbleRight {
  transform: translate(1.4em, -50%);
  -webkit-transform: translate(1.4em, -50%);
  -webkit-box-shadow: 7px 7px 17px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 7px 7px 17px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 7px 7px 17px -8px rgba(0, 0, 0, 0.75);
}

.speechBubble.bubbleLeft {
  transform: translate(-7em, -50%);
  -webkit-transform: translate(-7em, -50%);
  -webkit-box-shadow: -7px 7px 17px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -7px 7px 17px -8px rgba(0, 0, 0, 0.75);
  box-shadow: -7px 7px 17px -8px rgba(0, 0, 0, 0.75);
}

.speechBubble:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  border: 0.5em solid transparent;
  margin-top: -0.5em;
  z-index: 9 !important;
}

.speechBubble.bubbleRight:after {
  left: 0;
  border-right-color: white;
  border-left: 0;
  margin-left: -0.45em;
}

.speechBubble.bubbleLeft:after {
  right: 0;
  border-left-color: white;
  border-right: 0;
  margin-top: -0.5em;
  margin-right: -0.45em;
}
