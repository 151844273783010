.bulkyImage > .full {
  display: flex;
  width: 57px;
}

.bulkyImage > .small {
  display: none;
  width: 42px;
}

@media (max-width: 1186.98px) {
  .samedayBadgeText > .small {
    display: none;
  }
}

@media (max-width: 1069.98px) {
  .bulkyImage > .fullen {
    width: 48px;
  }
  .samedayBadgeText > .full {
    white-space: normal;
  }
  .samedayBadgeText > .fullen {
    font-size: 0.875rem;
  }
}

@media (max-width: 975.98px) {
  .bulkyImage > .fullen {
    width: 57px;
  }
  .samedayBadgeText > .small {
    display: inline;
  }
  .samedayBadgeText > .full {
    display: inline;
  }
  .samedayBadgeText > .fullen {
    font-size: 1rem;
  }
}

@media (max-width: 879.98px) {
  .samedayBadgeText > .smallen {
    display: none;
  }
}

@media (max-width: 849.98px) {
  .samedayBadgeText > .small {
    display: none;
  }
  .samedayBadgeText > .full {
    display: inline;
  }
}

@media (max-width: 809.98px) {
  .samedayBadgeText > .full {
    white-space: normal;
  }
}

@media (max-width: 767.98px) {
  .samedayBadgeText > .small {
    display: inline;
  }
  .samedayBadgeText > .full {
    display: inline;
  }
}

@media (max-width: 605.98px) {
  .samedayBadgeText > .smallen {
    display: none;
  }
}

@media (max-width: 424.98px) {
  .samedayBadgeText > .small {
    display: none;
  }
  .bulkyImage > .fullen {
    width: 48px;
  }
}

@media (max-width: 410.98px) {
  .samedayBadgeText > .full {
    display: none;
  }

  .bulkyImage > .full {
    display: none;
  }

  .bulkyImage > .small {
    display: flex;
  }
}
