.discimage {
    border: 1px solid #ccc;
    height: 245px;
    background-color: #eee;
    border-radius: 5px;
    position: relative;
    margin: 0px 20px;
}  

.discribbonbundle {
    width: 150px;
    height: 155px;
    font-size: .8em;
    color: #fff;
    font-weight: 700;
    text-align: center;
    letter-spacing: .025em;
    background-color: #ffcb00;
    position: relative;
    transition: all .2s ease-out;
    top: -8px;
    left: 0;
    padding-top: 0.5em;
    z-index: 2;
}

.discribbonbundle:before {
    content: "";
    position: absolute;
    border: 4px solid #eaad21;
    top: 0;
    right: -8px;
    border-top-color: transparent;
    border-right-color: transparent;
}

.discribbonbundle:after {
    content: "";
    position: absolute;
    bottom: -18px;
    left: 0;
    width: 0;
    height: 0;
    border-top: 18px solid #f9a01b;
    border-right: 75px solid transparent;
    border-left: 75px solid transparent;
}

.morecheap {
    position: absolute;
    font-size: 2.5em;
    transform: rotate(-15deg);
    color: #b8292f;
    white-space: nowrap;
    left: -.15em;
    top: 0.35em;
    letter-spacing: -.1px;
    text-shadow: -1px -1px 0 #fff, 0 -1px 0 #fff, 1px -1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff, -1px 1px 0 #fff, 0 1px 0 #fff, 1px 1px 0 #fff, -4px -4px 0 #fff, -1px -4px 0 #fff, 0 -4px 0 #fff, 1px -4px 0 #fff, 4px -4px 0 #fff, 4px -1px 0 #fff, 4px 0 0 #fff, 4px 1px 0 #fff, 4px 4px 0 #fff, 1px 4px 0 #fff, 0 4px 0 #fff, -1px 4px 0 #fff, -4px 4px 0 #fff, -4px 1px 0 #fff, -4px 0 0 #fff, -4px -1px 0 #fff;
}

.discpercent {
    font-size: 3em;
    position: absolute;
    bottom: -15px;
    width: 100%;
    text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
}

.lessStock {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    line-height: 1.2em;
}

.lessStockText {
    display: block;
    line-height: 1em;
}

.BundleQnt {
    position: absolute;
    right: 0;
    bottom: 0em;
}

.BundleQntBg {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background: #D0011B;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
}

.BundleQntText {
    font-size: 1.5em;
}

.disccirclebundle {
    width: 2em;
    height: 2em;
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    letter-spacing: .025em;
    border-radius: 50%;
    background-color: #b8292f;
    transition: all .2s ease-out;
    line-height: .7em;
    padding: 0.3em;
    left: 2px;
    z-index: 2;

}

.cornerribbon {
    width: 8em;
    height: 1.5em;
    padding: 5px 0px;
    position: absolute;
    text-align: center;
    line-height: 1em;
    transform: rotate(-45deg);
    left: -2.2em;
    top: 25px;
    z-index: 2;
}

.bundleImage {
    width: 100%;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bundleSelect input[type='checkbox'] {
    display: none;
  }
  .bundleSelect input[type='checkbox'] ~ span {
    position: relative;
    display: inline-block;
    width: 0.9em;
    height: 0.9em;
    margin-top: -2px;
    border-radius: 50%;
    vertical-align: middle;
    border: 2px solid #666;
    cursor: pointer;
  }
.bundleSelect input[type='checkbox'] ~ span:after {
    left: 0.25em;
    top: 0.15em;
    width: 5px;
    height: 9px;
    border: 2px solid #666666;
    border-width: 0px 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .bundleSelect input[type='checkbox']:checked ~ span {
    background-color: #b8292f;
    border-color: #b8292f;
  }
  .bundleSelect input[type='checkbox'] ~ span:after {
    content: '';
    position: absolute;
    display: none;
  }
  .bundleSelect input[type='checkbox']:checked ~ span:after {
    display: block;
    border-color: white;
  }

  .bundleSelect input[type='checkbox']:disabled ~ span {
    background-color:  #EBEBEB;
  }


  .discribbonbundlesoft {
    width: 180px;
    height: 100px;
    font-size: 0.8em;
    color: rgb(255, 255, 255);
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.025em;
    background-color: #f9a01b;
    position: relative;
    transition: all 0.2s ease-out;
    top: 0.5em;
    left: -9px;
    padding-top: 0.5em;
    z-index: 2;
    position: absolute;
  }
  
  .discribbonbundlesoft:before {
    content: "";
    position: absolute;
    border: 4px solid #eaad21;
    bottom: -8px;
    left: 0px;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  
  .discribbonbundlesoft:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 180px;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-right: 0px solid transparent;
    border-left: 20px solid #f9a01b;
  }
.morecheapsoft {
    position: absolute;
    font-size: 24px;
    color: #b8292f;
    white-space: nowrap;
    left: 10px;
    top: 10px;
    letter-spacing: -0.1px;
    text-shadow: 1px 1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
      -1px -1px 0 #fff, 1px 0px 0 #fff, 0px 1px 0 #fff, -1px 0px 0 #fff,
      0px -1px 0 #fff;
  }
  .discamount {
    margin: 0;
    position: absolute;
    top: 70%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    text-shadow: 1px 1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
      -1px -1px 0 #fff, 1px 0px 0 #fff, 0px 1px 0 #fff, -1px 0px 0 #fff,
      0px -1px 0 #fff;
    color: #b8292f;
  }
@media (max-width: 566.98px) {
    .BundleQntText {
        font-size: 0.75em;
    }

    .BundleQntBg {
        width: 1.2em;
        height: 1.2em;

    }

    .bundleImage {
        height: 150px;
    }


}