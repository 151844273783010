.buttonPrevious {
  left: 0 !important;
}

.buttonNext {
  right: 0 !important;
}

.buttonBg {
  background-color: white !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.8;
}

.buttonBg:hover {
  opacity: 1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.buttonLayout {
  position: absolute;
  z-index: 99;
  border-radius: 50%;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}

.buttonLayoutTop {
  position: absolute;
  z-index: 999;
  border-radius: 50%;
  cursor: pointer;
  top: 0;
}

.buttonLayout > .arrowLayout,
.buttonLayoutTop > .arrowLayout {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem;
  line-height: 1rem;
}

.awPopupContent img {
  max-height: 85vh !important;
}

@media (min-width: 1488px) {
  .buttonPrevious {
    left: -1rem !important;
  }
  .buttonNext {
    right: -1rem !important;
  }
}

@media (min-width: 480px) {
  .buttonLayout > .arrowLayout,
  .buttonLayoutTop > .arrowLayout {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
  }
}
