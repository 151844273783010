.textSizeShort {
  display: none;
}
.textSizeFull {
  display: inline-block;
}

@media (max-width: 1144.98px) {
  .textSizeShort {
    display: inline-block;
  }
  .textSizeFull {
    display: none;
  }
}

@media (max-width: 975.98px) {
  .textSizeShort {
    display: none;
  }
  .textSizeFull {
    display: inline-block;
  }
}

@media (max-width: 274.98px) {
  .textSizeShort {
    display: inline-block;
  }
  .textSizeFull {
    display: none;
  }
}
