.navProd {
  cursor: pointer;
  padding: 0px 0.25rem;
  margin: 10px 0px;
  outline: none !important;
  border: none !important;
  width: 100%;
}

.navProdBundle {
  cursor: pointer;
  padding: 0px 10px;
  margin: 10px 0px;
  outline: none !important;
  border: none !important;
  width: 100%;
}

.navArrow {
  box-shadow: #00000033 0px 0px 10px 0px;
  padding: 10px 10px 0px 10px;
  background-color: white;
  cursor: pointer;
  position: absolute;
  top: 35%;
  z-index: 99;
}

.SampleNextArrow {
  right: -15px;
}

.SamplePrevArrow {
  left: -15px;
}

.plusBundle {
  right: -0.25em;
  top: 3em;
  font-size: 2em;
}

.plusBundleImage {
  right: -0.25em;
  top: 1em;
  font-size: 2em;
}

.dots {
  bottom: 0px !important;
}

@media screen and (max-width: 992px) {
  .SampleNextArrow {
    right: 10px;
  }

  .SamplePrevArrow {
    left: 10px;
  }
  .plusBundle {
    top: 1em;
  }
}

@media (max-width: 566.98px) {
  .navProd {
    margin: 0px;
  }

  .navProdBundle {
    padding: 0px 5px;
    margin: 0px;
  }

  .plusBundle {
    top: 1.5em;
    font-size: 1em;
  }

  .plusBundleImage {
    top: 1.5em;
    font-size: 1em;
  }
}
@media (max-width: 383.98px) {
  .plusBundle, .plusBundleImage {
    top: 2.5em;
  }
}
